<script setup>
import {computed} from "vue";

const props = defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    variant: {
        type: String,
        default: 'primary',
    },
    size: {
        type: String,
        default: null,
    },
});

const classes = computed(() => {

    let padding = 'px-4 py-2';

    if (props.size === 'lg') {
        padding = 'px-6 py-3';
    } else if (props.size === 'sm') {
        padding = 'px-2 py-1';
    }

    const shared = `
        inline-flex items-center
        font-bold text-sm tracking-widest uppercase
        shadow rounded-lg
        ${padding}
        transition-colors duration-150 ease-in-out
    `;

    const classes = {
        primary: 'bg-dba-red-400 text-white hover:bg-dba-red-500 active:bg-dba-red-500 disabled:bg-dba-red-200',

        success: 'bg-green-500 text-white hover:bg-green-600 active:bg-green-600 disabled:bg-green-300',
        danger: 'bg-red-500 text-white hover:bg-red-600 active:bg-red-600 disabled:bg-red-300',
        warning: 'bg-orange-500 text-white hover:bg-orange-600 active:bg-orange-600 disabled:bg-orange-300',
        info: 'bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-600 disabled:bg-blue-300',
        light: 'bg-gray-200 text-gray-800 hover:bg-gray-300 active:bg-gray-300 disabled:bg-gray-300',
        dark: 'bg-gray-800 text-gray-200 hover:bg-gray-900 active:bg-gray-900 disabled:bg-gray-900',
    };

    return shared + ' ' + classes[props.variant];
});
</script>

<template>
    <button :type="type" :class="classes">
        <slot />
    </button>
</template>
